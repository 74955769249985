.payment-form {
    position: relative;
    width: 100%;
    display: inline-block;
    h2 {
        color: $link-color;
        font-size: 14px;
        font-weight: normal;
        text-align: left;

    }
    .form-group {
//        padding: 0 1em;
    }
    .form-group__{
        padding: 0.5em 1em;
    }

    .info__{
        padding: 1em 1em;
        font-size: 14px;
    }
    .error{
        padding: 0.4em 1em;
        color: red;
        font-size: 10px;
    }
    .form-check {
        text-align: left;

        label {
            padding-left: 19px;
            font-size: 14px;
            line-height: 1em;
        }
    }
    .payment-terms-agreement {
        padding-left: unset;
        margin: 2em 0;
    }
}
.payment-summary {
    margin: 1em 0;
    p {
        margin: 0;
        width: 100%;

        span {
            float: right;
        }
    }
    .total {
        font-weight: bold;
    }
}
