// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  background: #ddd;
}
#root {
  background: white;
  display: flex;
  align-content: flex-start;
  min-height: 100%;
  max-width: 900px;
  margin: 0 auto;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $link-color;

  @include on-event {
    color: $link-color;
    text-decoration: underline;
  }
}

.container {
  &.order,
  &.createuser {
    text-align: left;
    form {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0 auto;
      width: 100%;
    }
    .company-name {
      align-self: flex-start;
      font-size: 12px;
      line-height: 1.333em;
      color: #a4a4a4;
    }
  }
}

h1 {
  text-align: center;
}

p {
  font-size: 14px;
  line-height: 1.5em;
  text-align: left;
  &.preamble {
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;
    padding-bottom: 2vh;
  }
}

.steps {
  list-style: none;
  padding-left: 0;
  ul {
    margin: 20px 0 0 -40px;
    &::before {
      content: "";
      position: absolute;
      height: 0;
      width: 35px;
      margin-top: 20px;
      left: 0;
      border-top: 1px dashed #95c294;
    }
  }
  li {
    position: relative;
    min-height: 57px;
    font-size: 12px;
    line-height: 1.3em;
    padding-bottom: 10px;

    &::before {
      position: absolute;
      content: "";
      height: 40px;
      width: 40px;
      background: $brand-color;
      border-radius: 57px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }
    &.step01::before {
      background-image: url(/icon-inputfield.svg);
    }
    &.step02::before {
      background-image: url(/icon-member.svg);
    }
    &.step0201::before {
      background-image: url(/icon-accountant.svg);
    }
    &.step03::before {
      background-image: url(/icon-add_white.svg);
      background-position: center 45%;
    }
    &.step04::before {
      background-image: url(/icon-chevron-right_white.svg);
      background-position: 55% center;
    }
    &.step05::before {
      background-image: url(/icon-eye.svg);
    }
    &.step0501::before {
      background-image: url(/icon-accountant.svg);
    }
    &.step06::before {
      background-image: url(/icon-signator-white.svg);
      background-size: 40%;
    }
    strong {
      position: relative;
      display: block;
      margin-bottom: 8px;
      font-weight: normal;
      color: #4ca6ba;
      font-size: 17px;
      line-height: 1.18em;
      &::before {
        content: "";
        position: absolute;
        height: 0;
        width: 25px;
        top: 14px;
        left: -35px;
        border-top: 1px dashed #95c294;
      }
    }
    &:last-child {
      border-left: 3px solid white;
      margin-left: -2px;
    }
  }
  &.steps-vertical {
    border-left: 1px dashed #95c294;
    margin-left: 21px;

    li {
      padding-left: 55px;
      padding-top: 6px;
      &::before {
        left: -20px;
        top: 0;
      }
    }
  }
  &.steps-horizontal {
    position: relative;
    border-top: 1px dashed #95c294;
    display: flex;
    margin-top: 80px;
    li {
      text-align: center;
      padding-top: 10px;
      flex: 1;
      display: inline;
      &::before {
        left: calc(50% - 21px);
        top: -58px;
      }
      &::after {
        content: "";
        position: absolute;
        height: 17px;
        width: 0;
        left: calc(50% - 1px);
        top: -17px;
        border-left: 1px dashed #95c294;
      }
    }
    &::before,
    &::after {
      position: absolute;
      height: 0;
      width: 9vw;
      max-width: 76px;
      top: -1px;
      border-top: 1px solid white;
    }
    &::before {
      content: "";
      left: 0;
    }
    &::after {
      content: "";
      right: 0;
    }
  }
}
.form-termsandconditions {
  max-width: 80%;
}

article {
  text-align: left;
  blockquote {
    margin: 0;
    font-style: italic;
    p {
      margin: 0.5em 0;

      &:not(:first-of-type) {
        text-indent: 1em;
      }
    }
  }
}

.main-content {
  flex: 1 0 auto;
}

aside {
  display: none;
}

.spinner {
  width: 50px;
  margin-left: calc(50% - 25px);
}

.overlay {
  display: none;
  overflow: auto;
  z-index: 4;
  &-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
  }
}
.css-spinner {
  position: relative;
  display: none;
  min-height: 60px;
  height: 60px;
  width: 60px;
  margin: 8px auto;
  text-indent: -9999em;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 60px;
    width: 60px;
    clear: both;
    animation: rotation 0.6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, 0.15);
    border-right: 6px solid rgba(0, 174, 239, 0.15);
    border-bottom: 6px solid rgba(0, 174, 239, 0.15);
    border-top: 6px solid rgba(0, 174, 239, 0.8);
    border-radius: 100%;
  }
}
.has-overlay.has-spinner {
  .css-spinner {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
  }
}
.has-overlay {
  .overlay {
    display: flex;
  }
  .css-spinner {
    display: none;
  }
}
.has-spinner .css-spinner {
  display: block;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.table-members-list {
  margin: 1em 0 2em;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  th,
  td {
    text-align: left;
    vertical-align: top;
    font-weight: normal;
    padding: 4px;
    border-bottom: 1px solid #eaeaea;
  }
  th {
    color: $link-color;
    font-size: 11px;
  }
  .row-member {
    .member-role {
      width: 30px;
    }
    .member-name {
      min-width: 150px;
      font-weight: bold;
      a {
        color: #424242;
        text-decoration: none;
      }
    }
    .member-contactinfo div {
      max-width: 133px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: -26px;
    }
    .status {
      position: relative;
      padding-right: 2px;
      &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 15px;
        right: -12px;
        top: 0;
        background-image: url(/icon-chevron-left.svg);
        transform: rotate(180deg);
        background-position: center bottom 10px;
        background-repeat: no-repeat;
        background-size: 50%;
      }
      div {
        margin-top: 3px;
        height: 12px;
        width: 12px;
        border-radius: 100%;
        background-color: #eaeaea;
        background-image: url("/icon-signator-white.png");
        background-size: 6px auto;
        background-repeat: no-repeat;
        background-position: 50%;
        text-transform: lowercase;

        /*         &:after {
          padding: 2px;
          display: none;
          position: relative;
          top: -20px;
          left: -75px;
          width: 100px;
          text-align: center;
          background-color: #eee;
          border: 1px solid #ccc;
          border-radius: 2px;
          content: attr(data-title);
          text-transform: capitalize;
        }
        &:hover:after {
          display: block;
        }
         */

        &.READY_TO_SIGN {
          background-color: #ffa500;
        }
        &.SIGNED {
          background-color: $brand-color;
        }
      }
    }
  }
}

.signing-note {
  border: 2px solid red;
  font-weight: 600;
  padding: 4px;
}

// Reset table
table,
tr,
thead,
tbody,
td,
th {
  margin: none;
  padding: none;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}

@media only screen and (min-width: map-get($breakpoints, medium)) {
  body {
    //    filter: hue-rotate(-90deg);
    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      //      background: transparent url(//placekitten.com/1000/2000) 0 0 no-repeat;
      background: transparent url("/img-david-walman.jpg") 0 0 no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-position: center 20%;
    }
    #root {
      max-width: unset;
      background-color: unset;
      box-shadow: unset;
      &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: $brand-color, $alpha: 1);
        mix-blend-mode: multiply;
      }
    }
    .container {
      position: relative;
      margin: 0 auto;
      padding: 0 2vw;
      min-width: 768px;
      max-width: 1024px;
      display: flex;
      //      align-items: center; Vertical align content
      flex-direction: row;
    }
    header {
      box-shadow: unset;
      max-width: 375px;
      margin-top: 20vh;
    }
    main {
      margin-top: 15vh;
      width: 414px; // iphone plus size
      //       width: 745px; // made up size, visible up to 768px breakpoint
      height: 70vh;
      max-height: 800px;
      background: white;
      overflow: auto;
      flex: 0 0 auto;
      font-size: 14px;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    }
    aside {
      padding: 15vh 0 0 30px;
      display: block;
      flex: 0 1 auto;
      max-width: 600px;
      color: rgba($color: #ffffff, $alpha: 0.8);
      font-size: 25px;
      line-height: 1.3em;
      font-weight: bold;
      text-align: right;
      text-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.5);
      p {
        font-size: 25px;
        line-height: 1.3em;
        &.quote {
          font-size: 52px;
        }
        &.quoted {
          font-size: 20px;
        }
      }
      @media only screen and (max-width: map-get($breakpoints, large)) {
        p.quote {
          margin-top: 0;
          font-size: 5.08vw;
        }

      }
    }
    footer {
      background-color: transparent;
      box-shadow: unset;
      position: fixed;
      top: 0;
      width: 100%;
      bottom: unset;
      .logo {
        align-self: center;
        display: block;
        padding-top: unset;
        height: 58px;
        width: 58px;
        filter: brightness(0) invert(1);
      }
      .btn {
        filter: brightness(0) invert(1);
        &:focus {
          box-shadow: none;
        }
      }
    }
    .container.has-help,
    .container.has-navigation,
    .container.has-overlay {
      footer {
        background-color: inherit;
        .btn {
          filter: unset;
        }
      }
    }
  }
}

.language-select {
  flex: 0 0 100%;
  width: 100%;
}

@media only screen and (max-width: map-get($breakpoints, medium)) {
  .language-select {
    flex: unset;
  }
}

// refactor
.app .user-form {
  margin: 0;
  padding: 0;
  width: 100%;
  border: unset;
}
