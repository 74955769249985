// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.main-header {
    display: flex;
    position: sticky;
    z-index: 3;
    top: 0;
    margin-left: -30px;
    margin-right: -30px;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    align-self: flex-start;
    padding: 10px 0;
    width: 100%;
    margin: 0;
    .btn,
    div,
    a {
        flex: 1 0 33%;
        &:first-child {
            text-align: left;
            margin-bottom: 0;
            height: 58px;
        }
        &:last-child {
            text-align: right;
        }
    }
    a:hover {
        text-decoration: none;
    }
    .btn {
        min-width: unset;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        .icon {
            height: 1.5em;
            width: 1.5em;
        }
        &:focus {
            box-shadow: none;
        }
    }
    a {
        display: flex;
        &:first-child .btn {
            text-align: left;
        }
    }
    div,
    a .btn {
        padding: 8px;
        font-size: 20px;
        font-size: 18px;
    }
    div {
        font-weight: 600;
    }
}
.logo {
    background: transparent url(/logo-signator.svg) no-repeat top center;
    background-size: 31px auto;
    filter: grayscale(100%);
    padding: unset;
    height: unset;
    background-position: center center;
    text-indent: -999em;
}
.has-navigation .main-header {
    display: none;
}
