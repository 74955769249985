.container.articlepage {
    position: relative;
    background: white;
    max-width: 100%;
    .main-header {
        min-height: 62px;
        .btn-link {
            opacity: 0;
        }
    }
    main {
        text-align: left;
        max-width: 1024px;
        padding-top: unset;
        max-height: unset;
        width: 80%;
        margin: 0 auto;
        box-shadow: unset;
        height: unset;
    }
    footer {
        background: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

        .btn,
        .logo {
            filter: unset;
        }
    }
    .hero {
        text-align: center;
        .preamble {
            font-size: 23px;
            line-height: 1.5em;
            font-weight: 200;
            text-align: center;
        }
    }
    article {
        max-width: 770px;
        margin: 0 auto;
        h1 {
            font-size: 69px;
            line-height: 1.3em;
            color: $brand-color;
        }
        h2 {
            color: black;
            font-size: 52px;
            line-height: 1.3em;
            font-weight: 200;
            @media only screen and (max-width: map-get($breakpoints, small)) {
                font-size: 35px;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    @media only screen and (max-width: map-get($breakpoints, medium)) {
        main {
            width: 100%;
        }
        article {
            h1 {
                font-size: 42px;
            }
            h2 {
                font-size: 35px;
            }
        }
    }
    @media only screen and (min-width: map-get($breakpoints, medium)) {
        .main-header {
            display: none;
        }
        padding-top: 100px;
        footer {
            height: 93px;
        }
        .main-footer {
            .btn-navigation,
            .btn-help {
                height: 1px;
                width: 1px;
                overflow: hidden;
                opacity: 0;
            }
        }
    }
    &.services {
        h2 {
            font-size: 25px;
            margin-bottom: 0;
            + p {
                margin-top: 0;
            }
        }
    }
}
