.accountant-body::before {
    background-image: url(/img-morten-olsen.jpg) !important;
}
.container.accountant {
    .main-header .btn-link {
        opacity: 0;
    }
    .btn-success {
        margin: 1.5em 0;
    }
    aside {
        text-align: left;
        padding-top: calc(15vh + 52px);
    }
}
