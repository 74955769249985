// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  display: flex;
  flex-direction: column;
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  width: 100%; /* 1 */
  text-align: center;
}
main {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  //  padding-top: calc(42px + 6vh);
  //  padding-bottom: 100px;
}
.app, .main-content {
  flex: 1 0 auto;
  flex-direction: column;
  padding: 5vw 18px;
  @media only screen and (min-width: map-get($breakpoints, medium)) {
    padding: 5vh 30px; /* 3 */
  }
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hide {
  display: none !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.centered {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.text-align__center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.first-letter {
  display: block;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize !important;
  }
}
.translate-this {
  &::before {
    content: "Translate this!";
    display: block;
    position: absolute;
    color: red;
    margin-top: -1em;
    font-weight: bold;
    font-size: 1.2em;
  }
}
.show-on-desktop {
  display: none;
  @media only screen and (min-width: map-get($breakpoints, medium)) {
    display: inline-block;
  }
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}