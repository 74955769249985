// -----------------------------------------------------------------------------
// This file contains styles that are specific to the detail view page.
// -----------------------------------------------------------------------------

.container.detailview {
  .document {
    p,
    a {
      display: inline-block;
      flex: 1 0 auto;
    }
    p {
      width: calc(100% - 30px);
      align-self: flex-start;
      margin-right: auto;
    }
    .icon-document-1 {
      height: 35px;
      background-position: 0 0;
      align-self: flex-end;
      margin-top: -12px;
      text-indent: -9999em;
    }
  }
  .not-uploaded {
    display: none;
  }
  .disabled {
    .uploaded {
      display: none;
    }
    .not-uploaded {
      display: block;
      opacity: 0.4;
    }
  }
  &.summary {
    .table-members-list {
      .row-member {
        &:hover {
          cursor: pointer;
          .member-name {
            text-decoration: underline;
          }
        }
        &:hover {
          cursor: pointer;
          .member-name, 
          .member-contactinfo {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
