// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.main-footer {
  background-color: white;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  position: sticky;
  z-index: 6;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0;
  // Iphone X specific fix for bottom area
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  @media only screen and (min-width: map-get($breakpoints, medium)) {
    display: none;
  }
  nav {
    min-height: 80px;
    height: 80px;
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
    width: 100%;
    max-width: 1024px;
    button,
    a {
      margin-bottom: unset;
    }

    .btn {
      flex: 0 1 auto;
      &-search {
        flex: 0 1 65px;
        background: transparent url(/icon-search.svg) no-repeat 15px 18px;
        background-size: auto;
        border: 0;
        text-indent: -9999px;
      }
      &-navigation,
      &-help {
        padding: 20px;
        align-items: stretch;
        min-width: unset;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        height: 65px;
        width: 65px;
        flex: 0 1 65px;
        background-size: auto;
        border: 0;
        text-indent: -9999px;
        border-radius: unset;
      }
      &-navigation {
        background: transparent url(/icon-navigation.svg) no-repeat center;
      }
      &-help {
        opacity: 0;
        background: transparent url(/icon-help.svg) no-repeat center;
      }
      &-primary {
        display: none;
      }
      &:only-child {
        margin: 0 auto;
      }
    }
  }
  .has-navigation {
    .main-navigation {
      display: flex;
    }
    footer .btn-help {
      display: none;
    }
    .btn-navigation {
      position: relative;
      background-image: none;
      &:after {
        content: "+";
        width: 23px;
        height: 23px;
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: calc(50% - 0.33em);
        z-index: 1;
        margin: auto;
        padding: 0;
        color: #747474;
        font-family: $roboto, serif;
        font-weight: normal;
        font-size: 3em;
        line-height: 0.5em;
        text-align: center;
        text-indent: 0;
        cursor: pointer;
        transform: rotate(-45deg);
      }
    }
  }
}

.has-help {
  .overlay.overlay-help {
    z-index: 2;
    display: block;
    @media only screen and (min-width: map-get($breakpoints, medium)) {
      padding-top: 100px;
    }
  }
  footer {
    background-color: transparent;
    nav {
      justify-content: flex-end;
      .btn-help {
        justify-self: flex-end;
      }
      .logo {
        display: none;
      }
      .btn-navigation {
        display: none;
      }
    }
  }
  .btn-help {
    position: relative;
    background-image: none;
    &:after {
      content: "+";
      width: 23px;
      height: 23px;
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      right: calc(50% - 0.23em);
      z-index: 1;
      margin: auto;
      padding: 0;
      color: #747474;
      font-family: $roboto, serif;
      font-weight: normal;
      font-size: 3em;
      line-height: 0.5em;
      text-align: center;
      text-indent: 0;
      cursor: pointer;
      transform: rotate(-45deg);
    }
  }
}
.btn-about-cta {
  display: none;
}
.container.about {
  .btn-about-cta {
    display: flex;
  }
}
