// Start page styles only
.container.start {
    main {
        .logo {
            background-size: 80px;
            padding-top: 80px;
            filter: unset;
            @media only screen and (min-width: map-get($breakpoints, medium)) {
                display: none;
            }
        }
    }
    h1 {
        color: $brand-color;
        font-family: pt-serif, serif;
        font-weight: unset;
        @media only screen and (min-width: map-get($breakpoints, medium)) {
            font-size: 69px;
        }
    }
    .main-footer {
        .btn-primary {
            display: flex;
        }
    }
    header,
    .main-header {
        display: none;
    }
    @media only screen and (min-width: map-get($breakpoints, medium)) {
        .main-footer {
            display: block;
        }
    }
}
