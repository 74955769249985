// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
  display: none;
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  position: fixed;
  z-index: 6;
  text-align: center;
  width: 100%;
  background: white;
  @media only screen and (min-width: map-get($breakpoints, medium)) {
    display: flex;
  }
  nav {
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
    width: 100%;
    max-width: 1024px;
    // Iphone X specific fix for bottom area
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */

    button,
    a {
      margin-bottom: unset;
    }

    .btn {
      flex: 1 0 auto;
      padding: 20px;
      background: transparent;
      border-radius: unset;
      align-items: stretch;
      min-width: unset;
      height: 65px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      &-search {
        flex: 0 1 65px;
        background: transparent url(/icon-search.svg) no-repeat 15px 18px;
        background-size: auto;
        border: 0;
        text-indent: -9999px;
      }
      &-navigation {
        flex: 0 1 65px;
        background: transparent url(/icon-navigation.svg) no-repeat center;
        background-size: auto;
        border: 0;
        text-indent: -9999px;
      }
      &-help {
        opacity: 0;
        flex: 0 1 65px;
        background: transparent url(/icon-help.svg) no-repeat center;
        background-size: auto;
        border: 0;
        text-indent: -9999px;
        &:hover {
          opacity: 0;
        }
      }
    }
  }
  .has-navigation {
    .main-navigation {
      display: flex;
    }
    footer .btn-help {
      display: none;
    }
    .btn-navigation {
      position: relative;
      background-image: none;
      &:after {
        content: "+";
        width: 23px;
        height: 23px;
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: calc(50% - 0.33em);
        z-index: 1;
        margin: auto;
        padding: 0;
        color: #747474;
        font-family: $roboto, serif;
        font-weight: normal;
        font-size: 3em;
        line-height: 0.5em;
        text-align: center;
        text-indent: 0;
        cursor: pointer;
        transform: rotate(-45deg);
      }
    }
  }
}

.has-help {
  .overlay.overlay-help {
    z-index: 2;
    display: block;
    @media only screen and (min-width: map-get($breakpoints, medium)) {
      padding-top: 100px;
    }
  }
  footer {
    background-color: transparent;
    nav {
      justify-content: flex-end;
      .btn-help {
        justify-self: flex-end;
      }
      .logo {
        display: none;
      }
      .btn-navigation {
        display: none;
      }
    }
  }
  .btn-help {
    position: relative;
    background-image: none;
    &:after {
      content: "+";
      width: 23px;
      height: 23px;
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      right: calc(50% - 0.23em);
      z-index: 1;
      margin: auto;
      padding: 0;
      color: #747474;
      font-family: $roboto, serif;
      font-weight: normal;
      font-size: 3em;
      line-height: 0.5em;
      text-align: center;
      text-indent: 0;
      cursor: pointer;
      transform: rotate(-45deg);
    }
  }
}
.btn-about-cta {
  display: none;
}
.container.about {
  .btn-about-cta {
    display: flex;
  }
}
