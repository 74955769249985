/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $roboto, serif;
}
h2,
h3,
h4,
h5,
h6 {
  color: $brand-color;
}
h1 {
  color: black;
  font-size: 24px;
  line-height: 1.15em;
  font-weight: 200;
}
button,
input,
optgroup,
select,
textarea {
  font-family: $roboto;
}
