// -----------------------------------------------------------------------------
// This file contains all styles related to icons.
// -----------------------------------------------------------------------------
.icon {
    position: relative;
    font-style: normal;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 0.25rem;
    height: 1em;
    width: 1em;

    &-chevron-left {
        background-image: url("/icon-chevron-left.svg");
    }
    &-chevron-right {
        background-image: url("/icon-chevron-left.svg");
        transform: scaleX(-1);
    }
    &-chevron-up {
        background-image: url("/icon-chevron-left.svg");
        transform: scaleX(-1);
    }
    &-chevron-down {
        background-image: url("/icon-chevron-down.svg");
    }
    &-add {
        position: relative;
        margin-top: 0;
        margin-left: 0;
        width: 2em;
        height: 100%;
        min-width: unset;
        padding: unset;
        border: 1px solid transparent;
        background-color: transparent;
        display: inline-block;
        vertical-align: middle;
        outline: 0;
        text-indent: -999em;
        &:focus {
            outline: none;
            box-shadow: none;
            &:after {
                box-shadow: 0 0 5px #4ca6ba;
            }
        }

        &:after {
            color: #424242;
            text-indent: 0;
            content: "+";
            width: 1em;
            height: 100%;
            position: absolute;
            top: -1px;
            left: 8px;
            z-index: 1;
            margin: auto;
            padding: 0;
            font-family: $roboto, serif;
            font-size: 22px;
            line-height: 19px;
            text-align: center;
            cursor: pointer;
        }
    }
    &-document-1 {
        background-image: url("/icon-document-1.svg");
        height: 32px;
        width: 28px;
        background-size: auto;
    }
    &-document-2 {
        background-image: url("/icon-document-2.svg");
        height: 32px;
        width: 28px;
        background-size: auto;
    }
    &-grey {
        filter: grayscale(100%);
        opacity: 0.4;
        &:hover {
            cursor: default;
        }
    }
}
