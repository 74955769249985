// -----------------------------------------------------------------------------
// This file contains styles that are specific to the login page.
// -----------------------------------------------------------------------------
body {
  color: $text-color;
}
.container.login {
  h1 {
    font-size: 24px;
    line-height: 35px;
    font-weight: 600;
  }
  .userinput {
    min-height: 100px;
    margin-top: 50px;
    input {
      min-width: 280px;
      padding: 0 10px;
    }
  }
  .help {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 10px;
    .img {
      margin: 0 auto;
      height: 37px;
      width: 37px;
      border-radius: 38px;
      background: transparent url(/supportive-guy.png) no-repeat top center;
      background-size: 100% auto;
      margin-bottom: 5px;
    }
    min-height: 50px;
    margin: 0 auto;
    font-size: 15px;
  }
}
