.board-members {
    h1 .org-number {
        font-size: 0.7em;
        line-height: 1.5em;
    }
    p {
        font-size: 14px;
        line-height: 18px;
        text-align: left;
    }
}
