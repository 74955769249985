.upload-document {
  .app {
    display: flex;
    justify-content: center;
  }
  .pdf-viewer {
    display: block;
    height: 50vh;
    width: 100%;
    margin-bottom: 1em;
  }
}

// Display for two page types
.upload-document,
.detailview {
  input[type="file"] {
    text-indent: -999px;
    position: absolute;
    height: 98px;
    width: 98px;
    margin-left: -98px;
    opacity: 0;
    z-index: 3;
    &:hover {
      cursor: pointer;
    }
  }
}
