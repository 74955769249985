// -----------------------------------------------------------------------------
// This file contains all styles related to forms.
// -----------------------------------------------------------------------------
.form-group {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  align-items: stretch;
  p {
    margin: 0;
  }
}

label {
  flex: 0 1 100%;
  align-self: flex-start;
  display: inline-block;
  font-size: 11px;
  line-height: 1.333em;
  color: $link-color;
  margin-bottom: 5px;
  text-align: left;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea,
select {
  font-size: 12px;
  line-height: 1.333em;
  color: $text-color;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 2px;
  height: 34px;
  text-align: left;
  width: 100%;
  padding: 8px 10px;
  text-align: left;
  margin-bottom: 5px;
  &::placeholder {
    color: #a4a4a4;
  }
  &:focus {
    outline: $brand-color auto 5px;
  }
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

// Order step 1
input.company-orgnr {
  display: block;
  min-width: 270px;
}
#company-form .btn {
  margin-top: 5vh;
}

select.icon-chevron-down {
  background-color: white;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.select-css {
  display: block;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.2em 1.4em 0.2em 0.8em;
  //  width: 100%;
  //  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  //  border: 1px solid #aaa;
  border: unset;
  //  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  //  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  //  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px $brand-color;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: gray;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}
.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
  border-color: #aaa;
}

// Form feedback text
.feedback {
  margin-top: 4px;
  font-size: 14px;
}
.feedback__invalid {
  color: red;
}
.feedback__valid {
  color: $brand-color;
}

.form-check-input {
  position: absolute;
  margin-left: -25px;
  width: 25px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-inline {
  display: inline-flex;
  //  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;

  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
}
