.createuser {
    .form-group > .btn-remove {
        margin: -10px -10px 0 0
    }
    .form-group > div {
        input + .btn-clear {
            margin-top: 0;
        }
    }
}
.user-form {
    position: relative;
    border: 1px solid #d1d1d1;
    padding: 1em 1em 0;
    max-width: 100%;
    display: inline-block;
    margin: 1em 0;
}