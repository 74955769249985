// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpoints) {
  // If $mq-breakpoint is a key that exists in
  // $mq-breakpoints, get and use the value
  // https://jonsuh.com/blog/managing-responsive-breakpoints-with-sass-maps/
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }

  @media screen and (min-width: #{$mq-breakpoint}) {
    @content;
  }
}

@mixin navigationlinks() {
  font-weight: normal;
  &::before {
    position: absolute;
    top: 11px;
    left: 0;
    background-color: $link-color;
    height: 1em;
    width: 3px;
    content: "";
    border-radius: 3px;
  }
}
