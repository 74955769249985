.container {
    &.start .main-navigation .start,
    &.about .main-navigation .about,
    &.services .main-navigation .services,
    &.guide .main-navigation .guide,
    &.help .main-navigation .help,
    &.faq .main-navigation .faq,
    &.termsandconditions .main-navigation .termsandconditions,
    &.privacypolicy .main-navigation .privacypolicy,
    &.contact .main-navigation .contact {
        @include navigationlinks();
    }
}

.main-navigation {
    display: none;
    align-items: center;
    padding: 0 20vw;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 65px;
    left: 0;
    align-self: center;
    overflow: auto;
    background: white;
    transition: all 0.2s ease;
    z-index: 5;
    ul {
        text-align: left;
        list-style: none;
        z-index: 2;
        font-size: 24px;
        line-height: 28px;
        font-weight: 300;
        border-top-width: 0;
        padding: 0;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    li {
        position: relative;
        &.spacing {
            margin-bottom: 4vh;
        }
        &.active,
        &:hover {
            @include navigationlinks();
        }
    }
    a {
        display: block;
        width: 100%;
        padding: 8px 15px;
        text-decoration: none;
        cursor: default;
        &:hover {
            text-decoration: none;
        }
    }
}
.has-navigation {
    .main-navigation {
        display: flex;
    }
}
