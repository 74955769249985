.container.guide {
    main {
        text-align: left;
        @media only screen and (min-width: map-get($breakpoints, medium)) {
            .main-header {
                display: none;
            }
        }
    }
    .main-footer {
        .btn-primary {
            display: flex;
        }
        @media only screen and (min-width: map-get($breakpoints, medium)) {
            display: flex;
            z-index: 2;
            .btn-navigation,
            .btn-help {
                display: none;
            }
            .btn-primary {
                margin: 0 auto;
            }
        }
    }
}
