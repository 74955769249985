// Button css
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.btn {
  display: inline-block;
  text-decoration: none;
  height: 42px;
  font-size: 16px;
  color: white;
  border-radius: 100px;
  border: 0;
  padding: 9px 20px;
  min-width: 130px;
  text-align: center;
  align-self: center;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.95;
    text-decoration: none;
    color: white;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 5px $brand-color;
  }
  &:disabled,
  &-disabled {
    background: #e4e4e4;
    color: #a4a4a4;
    opacity: 0.5;
    cursor: default;
  }

  &-primary {
    background: $brand-color;
  }
  &-warning {
    background: orange;
  }
  &-danger {
    background: red;
  }
  &-light {
    background: transparent;
    color: $brand-color;
    border: 1px solid $brand-color;
    height: 42px;
    //    padding: 0 18px; why zero? KA
    &:hover {
      color: $brand-color;
    }
    &:disabled {
      background: transparent;
      border: 1px solid #a4a4a4;
      color: #a4a4a4;
      opacity: 0.5;
      cursor: default;
    }
  }
  &-large {
    height: 50px;
    padding: 13px 24px;
    font-size: 18px;
  }
  &-add {
    position: relative;
    margin-top: 0;
    margin-left: 0;
    width: 41px;
    min-width: unset;
    padding: unset;
    border: 1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    text-indent: -999em;
    &:focus {
      outline: none;
      box-shadow: none;
      &:after {
        box-shadow: 0 0 5px $brand-color;
      }
    }

    &:after {
      color: #424242;
      text-indent: 0;
      content: "+";
      width: 22px;
      height: 22px;
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 1;
      margin: auto;
      padding: 0;
      border-radius: 50%;
      font-family: $roboto, serif;
      font-size: 22px;
      line-height: 19px;
      text-align: center;
      cursor: pointer;
    }
    &-round {
      &:after {
        font-weight: lighter;
        color: $brand-color;
        border-radius: 50%;
        border: 1px solid $brand-color;
      }
    }
    &-primary {
      width: 56px;
      height: 56px;
      &:after {
        border: 0;
        top: 0;
        left: 0;
        width: 55px;
        height: 55px;
        color: white;
        background: $brand-color;
        font-size: 40px;
        line-height: 1.35em;
        font-weight: 300;
      }
      &-large {
        width: 98px;
        height: 98px;
        &:after {
          width: 97px;
          height: 97px;
          font-size: 70px;
        }
      }
    }
  }
  &-text {
    background: transparent;
    text-decoration: none;
    color: unset;
    min-width: unset;
    &:hover {
      color: unset;
    }
    &:focus {
      box-shadow: unset;
    }
  }
  &-link {
    background: transparent;
    color: $link-color;
    min-width: unset;
    text-decoration: underline;
    &:hover {
      color: $link-color;
    }

    &.btn-primary {
      background: inherit;
      color: $brand-color;
      &:hover {
        color: $brand-color;
      }
    }
    &.btn-warning {
      background: inherit;
      color: orange;
    }
    &.btn-danger {
      background: inherit;
      color: red;
    }
  }
  &-success {
    background: $brand-color;
    color: white;
  }
  + .btn {
    margin-left: 10px;
  }
}

.overlay {
  flex-direction: column;

  .btn.btn-close {
    border-radius: unset;
    align-self: flex-end;
    position: fixed;
    //    margin-right: -18px;
    // https://app.asana.com/0/1122393455520213/1205351958669241
    margin-right: 0;
    height: 65px;
    width: 65px;
    min-width: unset;
    margin-bottom: -65px;
    font-size: 0.0001px;
    background: white;
    color: white;
    z-index: 2;
    &::before,
    &::after {
      position: absolute;
      left: 50%;
      top: calc(50% - 10px);
      content: " ";
      height: 20px;
      width: 2px;
      background-color: #333;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

.btn-menu {
  border: 0;
  box-sizing: content-box;
  padding: 8px;
  position: fixed;
  bottom: 25px;
  left: 15px;
  height: 24px;
  width: 24px;
  background: rgba(255, 255, 255, 0.95) url("/icon-hamburger.svg") no-repeat 8px 8px;
}
.btn-notifications {
  border: 0;
  box-sizing: content-box;
  padding: 8px;
  position: absolute;
  top: 17px;
  right: 12px;
  height: 24px;
  width: 24px;
  background: transparent url("/icon-bell.svg") no-repeat 8px 8px;

  .number {
    position: absolute;
    right: 5px;
    top: 7px;
    width: 14px;
    height: 14px;
    background: #e43779;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 50%;
    font-weight: 600;
    font-size: 9px;
    text-align: center;
    line-height: 12px;
    text-align: center;
    color: white;
  }
}

// Clear input fields
.btn-clear {
  position: absolute;
  right: 0;
  margin-top: 0;
  height: 34px;
  width: 31px;
  min-width: unset;
  padding: unset;
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  text-indent: -999em;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    &:after {
      box-shadow: 0 0 5px #a4a4a4;
    }
  }

  &:after {
    content: "+";
    width: 23px;
    height: 23px;
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    right: 4px;
    z-index: 1;
    margin: auto;
    padding: 0;
    color: #a4a4a4;
    border-radius: 50%;
    font-family: $roboto, serif;
    font-weight: lighter;
    font-size: 25px;
    line-height: 23px;
    text-align: center;
    text-indent: 0;
    cursor: pointer;
    transform: rotate(-45deg);
  }
}
label + input + .btn-clear {
  margin-top: 19px;
}

input:not(:valid) ~ .btn-clear {
  display: none;
}

// Remove board member
.btn-remove {
  position: absolute;
  right: 19px;
  margin-top: -5px;
  height: 34px;
  width: 31px;
  min-width: unset;
  padding: unset;
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  text-indent: -999em;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
    &:after {
      box-shadow: 0 0 5px #a4a4a4;
    }
  }

  &:after {
    content: "+";
    width: 23px;
    height: 23px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4px;
    z-index: 1;
    margin: auto;
    padding: 0;
    color: #a4a4a4;
    border-radius: 50%;
    font-family: $roboto, serif;
    font-weight: lighter;
    font-size: 25px;
    line-height: 0.85em;
    text-align: center;
    text-indent: 0;
    cursor: pointer;
    transform: rotate(-45deg);
  }
}

.btn-centered {
  align-self: center;
}

.button-group__centered {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
